///
/// @file _checkout.scss
///
/// \brief EM-BASE PC checkout overrides, pulling over styles from the base site styling to the EM site theme
///
/// This file will serve as a "translation" stylesheet, extending what we can
/// from styles existing for the US site, on selectors that are equivalent to
/// what are provided for the US site (for which the estee_base/scss files were
/// developed).

$cart-container-width: 1024px; // comps
$checkout-sidebar-width: percentage(256px / $cart-container-width);

.cart-header {
  display: table-row;
  th,
  td {
    text-align: $ldirection;
    font-weight: normal;
  }
}

// the following items have been borrowed from the CSS files for BrZ EMEA, and have to do with basic layout concerns for the review page
.transaction-details {
  .transaction-item {
    float: $ldirection;
    width: 33%;
    margin-bottom: 10px;
    div {
      margin-#{$ldirection}: 10px;
    }
    dl {
      margin-#{$ldirection}: 15px;
    }
    dt {
      a {
        float: $rdirection;
      }
    }
    dd {
      clear: both;
    }
    &.clear {
      clear: $ldirection;
      div,
      dl {
        margin-#{$ldirection}: 0;
      }
    }
  }
}

.checkout__sidebar {
  width: $checkout-sidebar-width;
  @media #{$large-up} {
    // Gray background
    &:after {
      width: $checkout-sidebar-width;
      #{$ldirection}: 100% - $checkout-sidebar-width;
    }
  }
}

.checkout__content {
  width: 100% - $checkout-sidebar-width;
}

.checkout-table {
  width: 100%;
}

.cart-item__total {
  width: auto;
  float: $rdirection;
  text-transform: none;
}

.checkout {
  .address-form {
    @media #{$large-up} {
      width: 100%;
    }
    .form-item {
      padding-#{$ldirection}: 20px;
      &.left,
      &.full {
        @include swap_direction(padding, 0);
      }
    }
  }
  .continue-checkout.disabled {
    background-color: $color-light-gray;
    border: 1px solid;
  }
}

.packstation-link {
  clear: both;
}

.ie select {
  padding-#{$rdirection}: 0;
  background-image: none;
}

.samples_overlay #no-thanks {
  display: none;
}

.order_review .promo-sample-link {
  text-decoration: underline;
}

.checkout-table .cart-item__price {
  white-space: nowrap;
}

.checkout-table .cart-item__price.samples_price {
  white-space: normal;
}

.sample-products {
  .product.sample-cell {
    .product-img {
      img {
        width: 144px;
        height: 198px;
      }
    }
  }
}

#address_form_container {
  .address.fs {
    .radio.title_container {
      .title_option {
        display: inline-block;
        margin-#{$rdirection}: 10px;
        input[type='radio'] {
          width: 15px;
          height: 15px;
          margin-top: 3px;
        }
      }
    }
  }
}

#confirm {
  #confirmation-number {
    a {
      text-decoration: underline;
    }
  }
}

.customer-service {
  .sidebar-page__content {
    .shipping-time-table {
      th {
        vertical-align: bottom;
      }
    }
  }
}

select {
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ' ';
}

#review {
  #review-address-info {
    .transaction-details {
      h3.checkout__subtitle {
        float: $ldirection;
        width: auto;
        font-size: 11px;
      }
      a.address-book__controls-link {
        font-size: 9px;
      }
    }
  }
}

.checkout__content {
  @media #{$medium-up} {
    float: $ldirection;
  }
}

.checkout__sidebar {
  @media #{$medium-up} {
    float: $ldirection;
    &:after {
      width: $checkout-sidebar-width; // default is checkout
      #{$ldirection}: 100% - $checkout-sidebar-width;
      content: '';
      background-color: $color-gray-cs-side;
      height: 100%;
      position: absolute;
      top: 0;
      display: block;
      z-index: -1;
    }
  }
}

.recommended-products-panel input[type='submit'] {
  max-width: 100%;
}

#print-order {
  margin-top: 20px;
}

#order_detail .page-wrapper {
  padding-top: 82px;
}

.co_updates_enabled {
  .page-wrapper {
    .checkout {
      .checkout__content {
        width: 75% !important;
        float: $ldirection;
      }
      .checkout__sidebar {
        width: 25% !important;
        &:after {
          width: 35%;
          #{$ldirection}: 65%;
        }
      }
    }
  }
}

.checkout-table {
  .cart-item {
    border-bottom: none !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 641px) {
  .checkout {
    @include swap_direction(margin, 0px);
    .checkout__content {
      @include swap_direction(padding, 30px 15px);
      #recommended-products-panel {
        .btn-shopnow {
          padding: 1px 6px;
        }
      }
    }
    .checkout__sidebar {
      @include swap_direction(padding, 30px 15px);
      font-size: 12.5px;
      letter-spacing: 0em;
      h2 {
        font-size: 20px;
      }
      .offer_submit {
        font-size: 10px;
        letter-spacing: 0px;
      }
    }
  }
}

#checkout-sidebar {
  #offer-code-panel {
    .offer_code_form_container {
      #one-offer-only {
        span a {
          position: relative !important;
          float: left;
        }
      }
    }
  }
  .offer-code-panel {
    &__title {
      position: relative;
      margin-bottom: 0;
      .title-icon {
        background-size: auto;
        background-position: 0 -3269px;
        height: 53px;
        width: 53px;
        position: absolute;
        top: -16px;
        #{$checkout-right}: -10px;
      }
      &.open {
        .title-icon {
          background-size: auto;
          background-position: 0 -2957px;
          height: 53px;
          width: 53px;
        }
      }
    }
  }
}

#cboxContent {
  .formatter-123__content--2-up {
    width: 48%;
  }
  .special-offer-row-wrapper {
    .special-offer-row .special-offer-image.offer-image-right {
      margin-right: -2%;
    }
  }
}

.checkout {
  section {
    &.sign-in-panel {
      display: block;
    }
  }
  .payment-panel {
    .payment-type {
      display: block;
    }
  }
}

footer.page-footer {
  clear: both;
}

.viewcart {
  .checkout__sidebar {
    .offer-code-panel {
      @media #{$medium-up} {
        margin-top: 0;
      }
    }
  }
}

.viewcart-paypal-button {
  .paypal-button {
    text-align: $ldirection;
  }
  .terms-conditions {
    margin-top: 10px;
    input {
      position: inherit;
      margin-top: 4px;
      float: $ldirection;
    }
  }
}
#viewcart {
  .pg_wrapper {
    #wrapper {
      .cart-item {
        .offer_quantity {
          font-size: 15px;
          padding-top: 10px;
        }
      }
    }
  }
}
